import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import Head from 'next/head';
import ContentCardTypes from '~/constants/ContentCardTypes';
import PropTypes from 'prop-types';

/* global API_URL */

const defaultShareImageUrl = '/static/vectors/thescore-logo.svg';

const locale = language => {
  switch (language) {
    case 'en':
      return 'en_CA';
    default:
      return language;
  }
};

export const facebookOpenGraph = (title, url, imageUrl, description, type, language) => {
  let shareImageWidth;
  let shareImageHeight;

  if (imageUrl) {
    shareImageWidth = 1280;
    shareImageHeight = 966;
  } else {
    shareImageWidth = 1200;
    shareImageHeight = 630;
  }

  const tags = [
    <meta key="og:title" property="og:title" content={title} />,
    <meta key="og:type" property="og:type" content="article" />,
    <meta key="og:url" property="og:url" content={url} />,
    <meta key="og:locale" property="og:locale" content={locale(language)} />,
    <meta key="og:image" property="og:image" content={imageUrl} />,
    <meta key="og:image:secure_url" property="og:image:secure_url" content={imageUrl} />,
    <meta key="og:image:type" property="og:image:type" content="image/jpeg" />,
    <meta key="og:image:width" property="og:image:width" content={shareImageWidth} />,
    <meta key="og:image:height" property="og:image:height" content={shareImageHeight} />,
  ];

  if (type !== 'contentCard')
    tags.push(<meta key="og:description" property="og:description" content={description} />);

  return tags;
};

export const twitterSummaryCard = (title, description, imageUrl) => [
  <meta key="twitter:card" name="twitter:card" content="summary_large_image" />,
  <meta key="twitter:site" name="twitter:site" content="@theScore" />,
  <meta key="twitter:title" name="twitter:title" content={title ? title.substring(0, 70) : ''} />,
  <meta key="twitter:description" name="twitter:description" content={description} />,
  <meta key="twitter:image" name="twitter:image" content={imageUrl || defaultShareImageUrl} />,
];

export const socialMetaTags = (title, description, url, featureImageUrl, type, language) => [
  ...facebookOpenGraph(title, url, featureImageUrl, description, type, language),
  ...twitterSummaryCard(title, description, featureImageUrl),
];

export const twitterAppCard = apiUri => {
  const appMetaTags = [
    <meta name="twitter:card" content="app" />,
    <meta name="twitter:site" content="@theScore" />,
    <meta name="twitter:description" content={'Mobile-First Sports, Scores & News'} />,
    <meta name="twitter:app:name:iphone" content="theScore App" />,
    <meta name="twitter:app:id:iphone" content="285692706" />,
    <meta name="twitter:app:name:ipad" content="theScore App" />,
    <meta name="twitter:app:id:ipad" content="285692706" />,
    <meta name="twitter:app:name:googleplay" content="theScore App" />,
    <meta name="twitter:app:id:googleplay" content="com.fivemobile.thescore" />,
  ];

  if (apiUri) {
    const urlScheme = `thescore://${apiUri}`;

    return [
      ...appMetaTags,
      <meta name="twitter:app:url:iphone" content={urlScheme} />,
      <meta name="twitter:app:url:ipad" content={urlScheme} />,
      <meta name="twitter:app:url:googleplay" content={urlScheme} />,
    ];
  }
  return appMetaTags;
};

export const appLinks = apiUri => {
  const deepLink = `thescore://${apiUri}`;
  return [
    <meta key="al:ios:url" property="al:ios:url" content={deepLink} />,
    <meta key="al:ios:app_store_id" property="al:ios:app_store_id" content="285692706" />,
    <meta key="al:ios:app_name" property="al:ios:app_name" content="theScore App" />,
    <meta key="al:android:url" property="al:android:url" content={deepLink} />,
    <meta
      key="al:android:package"
      property="al:android:package"
      content="com.fivemobile.thescore"
    />,
    <meta key="al:android:app_name" property="al:android:app_name" content="theScore App" />,
  ];
};

export const knowledgeGraph = () => (
  <script
    key="knowledgeGraph"
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: `{
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name": "theScore.com",
        "alternateName": "theScore.com",
        "url": "https://www.thescore.com",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "${API_URL}/search?q={search_term_string}&size=20",
          "query-input": "required name=search_term_string"
        },
        "publisher": {
          "@context": "http://schema.org",
          "@type": "Organization",
          "@id": "https://www.thescore.com",
          "name": "theScore.com",
          "legalName": "theScore Inc.",
          "url": "https://www.thescore.com",
          "logo": "https://thescore-web-artifacts.s3.amazonaws.com/images/theScore-60px-h.png",
          "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+1-416-479-8812",
            "contactType": "customer support"
          }],
          "sameAs": [
            "https://www.facebook.com/theScore",
            "https://twitter.com/theScore",
            "https://instagram.com/thescore",
            "https://en.wikipedia.org/wiki/TheScore_Inc.",
            "https://plus.google.com/101067809538208326678"
          ]
        }
      }`,
    }}
  />
);

export const richContent = (contentCard, contentType = 'contentCard') => {
  if (isEmpty(contentCard)) {
    return null;
  }

  // All baseContent key and value has to use double quotes to be valid
  const parsedContentCard = {};

  const type = contentType === 'article' ? 'Article' : 'CreativeWork';

  Object.keys(contentCard).forEach(key => {
    parsedContentCard[key] =
      typeof contentCard[key] === 'string'
        ? contentCard[key].replace(/["']/g, "'")
        : contentCard[key];
  });

  let baseContent = `
    "@context": "http://schema.org",
    "@type": "${type}",
    "headline": "${parsedContentCard.title}",
    "mainEntityOfPage": "${parsedContentCard.url}",
    "description": "${parsedContentCard.description}",
    "datePublished": "${moment(parsedContentCard.publishedAt).toISOString()}",
    "author": {
      "@type": "Person",
      "name": "${parsedContentCard.author}"
    },
  `;

  if (contentType === 'article') {
    baseContent += `"dateModified": "${moment(parsedContentCard.updatedAt).toISOString()}",`;
  }

  if (contentCard.isVideo) {
    baseContent += `
      "video": {
        "@type": "VideoObject",
        "name": "${parsedContentCard.title}",
        "description": "${parsedContentCard.title}",
        "thumbnailUrl": "${parsedContentCard.mainImage}"
      },
    `;
  }

  if (!contentCard.isVideo) {
    baseContent += `
      "image": {
        "@type": "ImageObject",
        "url": "${parsedContentCard.mainImage}",
        "height": 576,
        "width": 768
      },
    `;
  }

  return (
    <script
      key="contentCardRichContent"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
          ${baseContent}
          "publisher": {
            "@type": "Organization",
            "name": "theScore",
            "logo": {
              "@type": "ImageObject",
              "url": "https://thescore-web-artifacts.s3.amazonaws.com/images/theScore-60px-h.png",
              "width": 330,
              "height": 60
            }
          }
        }`,
      }}
    />
  );
};

const formattedContentCard = contentCard => {
  const { data, caption } = contentCard;

  const title = data.title || data.text || caption || '';
  const description = data.content ? data.content.substring(0, 200) : title.substring(0, 200);

  const baseCardData = {
    publishedAt: data.published_at,
    updatedAt: data.updated_at,
    title,
    url: contentCard.permalink,
    description,
    author: 'theScore staff',
    mainImage: defaultShareImageUrl,
  };

  switch (contentCard.type) {
    case ContentCardTypes.INSTAGRAM_PHOTO:
      return {
        ...baseCardData,
        mainImage: get(data, 'thumbnails.medium.url', defaultShareImageUrl),
        author: data.author_handle,
      };
    case ContentCardTypes.TWITTER_ANIMATED_GIF:
      return {
        ...baseCardData,
        mainImage: get(data, 'thumbnails.medium.url', defaultShareImageUrl),
        author: data.author_handle,
        isVideo: true,
      };
    case ContentCardTypes.TWITTER_VIDEO:
      return {
        ...baseCardData,
        mainImage: get(data, 'thumbnails.medium.url', defaultShareImageUrl),
        author: data.author_handle,
        isVideo: true,
      };
    case ContentCardTypes.TWITTER_VIDEO_STREAM:
      return {
        ...baseCardData,
        mainImage: get(data, 'thumbnails.medium.url', defaultShareImageUrl),
        author: data.author_handle,
        isVideo: true,
      };
    case ContentCardTypes.INSTAGRAM_VIDEO:
      return {
        ...baseCardData,
        mainImage: get(data, 'images.standard_resolution.url', defaultShareImageUrl),
        author: data.author_handle,
        isVideo: true,
      };
    case ContentCardTypes.YOU_TUBE:
      return {
        ...baseCardData,
        mainImage: get(data, 'thumbnails.standard', defaultShareImageUrl),
        title: data.title,
        author: data.channel_title,
        isVideo: true,
      };
    case ContentCardTypes.THE_SCORE_ARTICLE: {
      return {
        ...baseCardData,
        mainImage: get(data, 'feature_image.w768xh576.url', defaultShareImageUrl),
        author: get(data, 'authors[0].name', 'theScore staff'),
        isVideo: get(data, 'data.feature_video_id', false),
        shareUrl: data.share_url,
      };
    }

    case ContentCardTypes.SOURCED_ARTICLE:
      return {
        ...baseCardData,
        mainImage: get(data, 'image_url', defaultShareImageUrl),
        author: get(data, 'site_name', defaultShareImageUrl),
        description: get(data, 'description', ''),
        title: get(data, 'title', ''),
      };
    case ContentCardTypes.TWITTER_GALLERY_CARD:
      return {
        ...baseCardData,
        mainImage: get(data, 'gallery[0].images.medium.url', defaultShareImageUrl),
        author: data.author_handle,
      };
    case ContentCardTypes.TWITTER_PHOTO:
      return {
        ...baseCardData,
        mainImage: get(data, 'images.medium.url', defaultShareImageUrl),
        author: data.author_handle,
      };
    case ContentCardTypes.ROTO_NEWS:
      return {
        ...baseCardData,
        mainImage: get(data, 'headshots.large', defaultShareImageUrl),
        author: 'rotowire',
      };
    case ContentCardTypes.PLAYER_UPDATE:
      return {
        ...baseCardData,
        mainImage: get(data, 'headshots.large', defaultShareImageUrl),
        author: 'rotowire',
        title: get(data, 'player.full_name', ''),
      };
    case ContentCardTypes.TWITTER_TEXT:
      return {
        ...baseCardData,
        mainImage: defaultShareImageUrl,
        author: data.author_handle,
      };
    default:
      return baseCardData;
  }
};

const formatArticle = article => {
  const description = (article.content || article.title).replace(/<(.|\n)*?>/g, '');

  return {
    publishedAt: article.published_at,
    title: article.title,
    url: article.share_url,
    description,
    mainImage: get(article, 'feature_image.w768xh576.url', defaultShareImageUrl),
    author: get(article, 'authors[0].name', 'theScore staff'),
    isVideo: get(article, 'article.feature_video_id', false),
  };
};

const getSportName = resourceUri => capitalize(resourceUri.split('/')[1]);

const getPlayerTitle = player => {
  const name = get(player, 'full_name', '');
  const sport = getSportName(get(player, 'resource_uri', '/'));
  return `${name}: Stats, Age, News | ${sport}`;
};

const getTeamTitle = team => {
  const name = get(team, 'full_name', '');
  const sport = getSportName(get(team, 'resource_uri', '/'));
  return `${name} | News & Stats | ${sport}`;
};

const getEventTitle = league => {
  const slug = get(league, 'slug', '');
  const sport = capitalize(get(league, 'sport_name', ''));
  return `${slug.toUpperCase()} Scores | ${sport} | Scoreboard & Results`;
};

const getMatchupTitle = event => {
  const slug = get(event, 'league.slug', '');
  const sport = capitalize(get(event, 'league.sport_name', ''));
  const homeTeam = get(event, 'home_team.full_name', 'TBD');
  const awayTeam = get(event, 'away_team.full_name', 'TBD');
  const gameDate = event.game_date ? moment(event.game_date).format('MMMM D, YYYY') : 'TBD';

  return `${awayTeam} vs. ${homeTeam} | ${gameDate} | ${slug.toUpperCase()} | ${sport}`;
};

const getResultsMatchupTitle = event => {
  const tournamentName = get(event, 'name', '');
  const slug = get(event, 'league.slug', '');
  const sport = capitalize(get(event, 'league.sport_name', ''));

  return `${tournamentName} Results | ${slug.toUpperCase()} | ${sport}`;
};

const getRiverTitle = (slug, sportName) => {
  const sport = sportName ? capitalize(sportName) : 'NFL NHL MLB NBA & more';
  switch (slug) {
    case 'top_news':
      return `Sports News | ${sport}`;
    case 'trending':
      return `Trending News | ${sport}`;
    case 'wolym':
      return 'PyeongChang 2018 Coverage';
    default:
      return `${slug.toUpperCase()} News | ${sport}`;
  }
};

const getRiverDescription = slug => {
  switch (slug) {
    case 'top_news':
      return 'Top News & Rumors for Football, Basketball, Baseball, Hockey, Soccer & More';
    case 'nfl':
      return 'National Football Association - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'nba':
      return 'National Basketball Association - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'mlb':
      return 'Major League Baseball - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'nhl':
      return 'National Hockey League - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'ncaaf':
      return 'College Football - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'ncaab':
      return 'College Basketball - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'epl':
      return 'English Premier League - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'mls':
      return 'Major League Soccer - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    default:
      return 'Trending News & Rumors for Football, Basketball, Baseball, Hockey, Soccer & More';
  }
};

const getMatchupDescription = slug => {
  switch (slug) {
    case 'top_news':
      return 'Top News & Rumors for Football, Basketball, Baseball, Hockey, Soccer & More';
    case 'nfl':
      return 'National Football Association - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'nba':
      return 'National Basketball Association - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'mlb':
      return 'Extensive coverage of live scores, in depth stats, matchup details, and breaking news for every Major League Baseball game. theScore offers MLB scores, rumors, videos, standings and much more.';
    case 'nhl':
      return 'National Hockey League - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'ncaaf':
      return 'College Football - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'ncaab':
      return 'College Basketball - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'epl':
      return 'English Premier League - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    case 'mls':
      return 'Major League Soccer - Scores, Stats, News, Rumors, Videos, Standings, Teams';
    default:
      return 'Trending News & Rumors for Football, Basketball, Baseball, Hockey, Soccer & More';
  }
};

const MetaTags = props => {
  const { leagueSlug, content, type } = props;
  const altLeagueSlug = content.slug || leagueSlug || get(content, 'league.slug', '');
  let formattedContent;
  let contentType;
  let uri;
  let metaDescription = <meta name="description" content={getRiverDescription(altLeagueSlug)} />;

  if (isEmpty(content)) {
    return null;
  }

  if (type === 'contentCard') {
    formattedContent = formattedContentCard(content);
    contentType = content.type;
    uri = content.data.uri;
  } else if (type === 'article') {
    formattedContent = formatArticle(content);
    contentType = ContentCardTypes.THE_SCORE_ARTICLE;
    uri = content.uri;
  } else if (type === 'player') {
    formattedContent = {
      title: getPlayerTitle(content),
    };
    contentType = type;
  } else if (type === 'team') {
    formattedContent = {
      title: getTeamTitle(content),
    };
    contentType = type;
  } else if (type === 'events') {
    formattedContent = {
      title: getEventTitle(content),
    };
    contentType = type;
  } else if (type === 'matchup') {
    formattedContent = {
      title: getMatchupTitle(content),
      description: getMatchupDescription(altLeagueSlug),
    };
    metaDescription = <meta name="description" content={formattedContent.description} />;
    contentType = type;
  } else if (type === 'matchup_stats') {
    formattedContent = {
      title: `${getMatchupTitle(content)} | Stats`,
      description: getMatchupDescription(altLeagueSlug),
    };
    metaDescription = <meta name="description" content={formattedContent.description} />;
    contentType = type;
  } else if (type === 'matchup_preview') {
    formattedContent = {
      title: `${getMatchupTitle(content)} | Preview`,
      description: getMatchupDescription(altLeagueSlug),
    };
    metaDescription = <meta name="description" content={formattedContent.description} />;
    contentType = type;
  } else if (type === 'matchup_recap') {
    formattedContent = {
      title: `${getMatchupTitle(content)} | Recap`,
      description: getMatchupDescription(altLeagueSlug),
    };
    metaDescription = <meta name="description" content={formattedContent.description} />;
    contentType = type;
  } else if (type === 'results_matchup') {
    formattedContent = {
      title: getResultsMatchupTitle(content),
    };
  } else if (type === 'leaders') {
    formattedContent = {
      title: leagueSlug
        ? `${leagueSlug.toUpperCase()} Stats and League Leaders`
        : 'Stats and Leaders',
    };
    contentType = type;
  } else if (type === 'standings') {
    formattedContent = {
      title: leagueSlug ? `${leagueSlug.toUpperCase()} Standings` : 'Standings',
    };
    contentType = type;
  } else if (type === 'standingsPost') {
    const titleString = leagueSlug
      ? `${leagueSlug.toUpperCase()} Standings Post Season`
      : 'Standings Post Season';

    formattedContent = {
      title: titleString,
      description: titleString,
    };
    contentType = type;
  } else {
    formattedContent = {
      title: getRiverTitle(content.slug, content.sport_name),
      description: getRiverDescription(leagueSlug),
    };
    contentType = 'River';
    uri = content.uri;
  }

  return (
    <Head>
      <title key="pageTitle">{`${formattedContent.title} | theScore.com`}</title>
      {socialMetaTags(
        formattedContent.title,
        formattedContent.description,
        formattedContent.url,
        formattedContent.mainImage,
        type,
        locale('en')
      )}
      {metaDescription}
      {contentType === ContentCardTypes.THE_SCORE_ARTICLE && (
        <link rel="canonical" href={formattedContent.shareUrl || formattedContent.url} />
      )}
      {richContent(formattedContent, type)}
      {contentType === ContentCardTypes.THE_SCORE_ARTICLE && appLinks(uri)}
    </Head>
  );
};

MetaTags.propTypes = {
  leagueSlug: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  type: PropTypes.string.isRequired,
};

MetaTags.defaultProps = {
  leagueSlug: null,
};

export default MetaTags;
